<template>
  <v-container>
    
    <v-row v-if="isLogged">
      <v-col cols="12">

        <v-row>
          <v-col class="text-right">
            <v-btn @click="logout()" color="secondary" >
              <v-icon class="mr-2">mdi-logout</v-icon>
              Cerrar Sesión
            </v-btn>
          </v-col>
        </v-row>

        <v-banner two-line elevation="2" class="mb-5 mt-3" >

          <v-avatar slot="icon" color="green" size="50" >
            <v-icon dark> mdi-book-open-variant </v-icon>
          </v-avatar>

          <p class="text-h5 text--primary"> Sistema de Gestión de Contenido </p>
          <span class="body-2">Seleccione a continuación una categoría para ver, crear, editar o eliminar su contenido</span>
          
        </v-banner>


        <v-row>

          <v-list two-line class="pl-5">

            <template v-for="item in menus">

              <v-list-item :key="item.title" router :to="item.link" >
                <v-list-item-icon>
                  <v-icon>{{item.icon}}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title >{{item.name}}</v-list-item-title>
                  <v-list-item-subtitle >{{item.subtitle}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider :key="item.title" inset />

            </template>

          </v-list>

        </v-row>

      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" align="center">

        <v-card class="mt-3 py-3">

        <v-img src="@/assets/logoGalpon.png" max-width="160px" class="mb-3" />

        <h3>Administración</h3>

        <p>Para acceder a esta sección, debe iniciar sesión</p>

        <v-btn @click="openLogin = true" color="primary">
          <v-icon class="mr-2">mdi-account-circle</v-icon>
          Iniciar Sesión
        </v-btn>

        </v-card>

      </v-col>
    </v-row>

    <!-- Login form -->
    <LoginDialog v-model="openLogin" />

    <!-- Logout Notification -->
    <v-snackbar
      v-model="isOpenLogoutNotif"
      :timeout="2000"
      top
      color="blue lighten-1"
    >
      Has cerrado sesión exitosamente

      <template v-slot:action="{ attrs }">
        <v-btn
          color="secondary"
          text
          v-bind="attrs"
          @click="isOpenLogoutNotif = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
import {mapState} from 'vuex'
import LoginDialog from "@/components/LoginDialog"

export default {
  name: "Admin",
  components: {
    LoginDialog,
  },
  computed: {
    ...mapState([
      'user'
    ]),
    isLogged() {
      return !!this.$store.state.user.email
    },
  },
  data() {
    return {
      openLogin: false,
      isOpenLogoutNotif: false,
      menus: [
        { name: 'Noticias', subtitle: 'Gestione las noticias mostradas en la página', icon: 'mdi-newspaper', link: '/admin/news'},
        { name: 'Programación', subtitle: 'Planifique el itinerario del Galpón', icon: 'mdi-calendar', link: '/admin/programming'},
        { name: 'Profesores', subtitle: 'Ver y editar los profesores registrados', icon: 'mdi-school', link: '/admin/professors'},
        { name: 'Talleres', subtitle: 'Gestione los talleres impartidos por el Galpón', icon: 'mdi-human-male-board', link: '/admin/courses' },
        { name: 'Inscripción de Talleres', subtitle: 'Active, desactive y edite la funcionalidad de inscripción de talleres', icon: 'mdi-draw', link: '/admin/registrationstatus' },
        { name: 'Galería', subtitle: 'Edite las secciones de la galería', icon: 'mdi-image-multiple', link: '/admin/gallery' },
        { name: 'Info. de Contacto', subtitle: 'Modifique la información de contacto que se muestra al público', icon: 'mdi-card-account-mail', link: '/admin/contact' },
        { name: 'Equipo de Trabajo', subtitle: 'Administre la visibilidad del personal del Galpón', icon: 'mdi-account-wrench', link: '/admin/staff' },
        { name: 'Redes Sociales', subtitle: 'Habilite/Deshabilite y cambie los links de las redes sociales', icon: 'mdi-account-supervisor', link: '/admin/socialnetworks' },
      ]
    }
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch('API_LOGOUT')
        this.isOpenLogoutNotif = true
      } catch (e) {
        console.log(e)
      }
    }
  },
}
</script>
