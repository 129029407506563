<template>
  
  <v-dialog
    transition="dialog-top-transition"
    width="400"
    v-model="show"
    :fullscreen="$vuetify.breakpoint.xs"
  >
  

    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
      Iniciar sesión en Galpón Cultural
      </v-toolbar>


      <v-container class="px-4">

        <!-- Alert: error logging in -->
        <v-alert
          :value="errorLoggingIn"
          text
          type="error"
          transition="scroll-y-transition"
          class="my-3"
        >

          <v-row align="center">
            <v-col class="grow">
              Usuario y/o contraseña incorrecta, inténtalo de nuevo
            </v-col>
          </v-row>
          
        </v-alert>

        <!-- Alert: missing logging in fields -->
        <v-alert
          :value="missingLoginData"
          text
          type="warning"
          transition="scroll-y-transition"
          class="my-3"
        >

          <v-row align="center">
            <v-col class="grow">
              Debes ingresar tu email y contraseña para continuar
            </v-col>
          </v-row>
          
        </v-alert>

        <!-- Fields -->
        <v-text-field
          v-model="loginData.email"
          label="Email"
        />

        <v-text-field
          v-model="loginData.password"
          label="Contraseña"
          :type="viewPassword ? 'text' : 'password'"
        >

          <v-icon
            slot="append"
            color="primary"
            @click="viewPassword = !viewPassword"
          >
            {{viewPassword ? 'mdi-eye-off' : 'mdi-eye'}}
          </v-icon>

        </v-text-field>

      </v-container>

      <v-card-actions class="pb-3">

        <v-row>
          <v-col class="text-right">

            <v-btn 
              @click.stop="closeDialog()"
              class="mr-2"
              :disabled="isLoggingIn"
            >Volver atrás</v-btn>

            <v-btn
              color="success"
              @click="login()"
              class="align-right"
              :loading="isLoggingIn"
              :disabled="isLoggingIn"
            >Iniciar Sesión</v-btn>

          </v-col>
        </v-row>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>

export default {
  name: 'LoginDialog',
  props:{
    value: Boolean,
  },
  data() {
    return {
      //view control
      isLoggingIn: false,
      errorLoggingIn: false,
      missingLoginData: false,
      viewPassword: false,
      //form data
      loginData: {
        email: null,
        password: null
      },
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value)  {
         this.$emit('input', value)
      }
    },
  },
  methods: {
    async login() {
      try {

        this.missingLoginData = false

        if(!this.loginData.email || !this.loginData.password) {
          this.missingLoginData = true
          return
        }

        this.isLoggingIn = true
        this.errorLoggingIn = false
        this.successSignupAlert = false

        const loginResp = await this.axios.post(`${process.env.VUE_APP_APIURL}/user/login`, this.loginData)
        
        if(!loginResp) {
          throw 'invalid api login response'
        }

        await this.$store.dispatch('API_LOGIN', loginResp.data.data)

        this.isLoggingIn = false
        this.emptyLoginFields()

        this.show = false
        this.$emit('login')

      } catch (e) {
        this.isLoggingIn = false
        this.errorLoggingIn = true
        throw e
      }
    },
    emptyLoginFields() {
      this.loginData = {
        email: null,
        password: null
      }
    },
    closeDialog() {
      this.show = false
      this.errorLoggingIn = false
      this.missingLoginData = false
      this.emptyLoginFields()
    }
  }
}
</script>